body {
  box-shadow: inset 0px 0px 30px -6px rgba(201, 201, 201, 1);
  font-family: "Verdana", Open Sans, Calibri !important;
}

img {
  pointer-events: none;
  user-select: none;
}

h4 {
  border-left: 4px solid #707785;
  padding-left: 14px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-radius: 4px;
}

i {
  margin-left: 7px;
  width: 15px;
}

button {
  box-shadow: 2px 2px 6px -1px rgba(145, 145, 145, 1);
}

span,
input,
label {
  text-align: left;
}

label {
  display: inline-block !important;
}

@font-face {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Cairo-Regular.ttf");
}

.bms-content-component hr {
  margin-top: 5px;
  margin-bottom: 10px;
}

.flex-container {
  display: flex;
  flex-direction: row; /* >>> */
  flex-wrap: nowrap; /* Flex items are on a single line */
  justify-content: flex-start; /* Horizontal alignment of flex items: Left, center, right and many others */
  align-items: stretch; /* How flex items occupy vertical space */
  align-content: flex-start;
}

.bms-content {
  order: 2;
  flex-grow: 1;
  width: 83%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
}

.bms-content-component {
  width: 99%;
}

.flex-container-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* Flex items are on a single line */
  justify-content: flex-end; /* Horizontal alignment of flex items: Left, center, right and many others */
  align-items: center; /* How flex items occupy vertical space */
  align-content: center;
  /* margin: 5px; */
  /* margin-bottom: 10px; */
}

.flex-item-left {
  flex-grow: 0; /* Streches the block */
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}

.p-button-icon-left {
  left: 0.8em !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.error-toast {
  background: linear-gradient(
    135deg,
    #9b1030 0%,
    #9b1030 9%,
    #9b1030 72%,
    #9b1030 100%
  );
  color: aliceblue;
}

.info-toast {
  background: linear-gradient(
    135deg,
    #4fa0ec 0%,
    #4fa0ec 9%,
    #4fa0ec 72%,
    #4fa0ec 100%
  );
  color: aliceblue;
}

.logged-in-as {
  font-size: 12px;
  text-align: center;
  color: white;
  margin: 0px 0px 0px 0px;
}

.username {
  color: #f53;
  font-weight: bold;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.clear-fix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.aps-logo {
  margin: 0 auto;
  margin-top: 150px;
  opacity: 0.2;
}

@media screen and (max-width: 1500px) {
  .aps-logo {
    margin: 0 auto;
    margin-top: 110px;
    opacity: 0.2;
  }
}

.modal-header {
  margin: -15px;
  display: inline-block;
}

.multiselect {
  display: block;
  height: 31px;
}

.dropdown {
  width: 100% !important;
  height: 39px;
}

.textarea {
  display: block;
  width: 100% !important;
}

.calendar {
  border: none;
  padding-top: 4px;
  padding-right: 0px;
  padding-left: 0px;
}

span > input {
  width: 100%;
  display: inline-block;
}

.codeeditor {
  display: block;
  width: 100% !important;
}

.btn-popup-menu {
  cursor: pointer;
  box-shadow: none;
  font-size: 11px !important;
}

#outer {
  width: 100%;
  text-align: center;
}
.inner {
  display: inline-block;
}

.btn-popup-menu:hover {
  cursor: pointer;
  color: white;
  background-color: #a5aec0;
  border-radius: 10px;
}

.p-multiselect-close,
.p-dialog-titlebar-icon {
  box-shadow: none;
}

.bms-toast {
  border-radius: 10px !important;
  opacity: 0.92;
}

.fade-in {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

.selection > ::selection {
  color: #558dff;
  background: rgb(255, 255, 255);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body .p-component,
.Toastify__toast {
  font-family: "Verdana", Open Sans, Calibri;
}

.p-button .p-button-text {
  line-height: inherit !important;
}

.p-dropdown-panel .p-dropdown-item {
  text-align: left;
}

.p-dropdown .p-dropdown-label {
  /*padding: 0.32em 3.5em !important;*/
}

.app-info {
  text-align: left !important;
}

.p-multiselect-panel .p-multiselect-item {
  text-align: left !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin: 0 0.5em 0.5em 0.5em;
}

body .p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin: 0 0.5em 0 0.5em;
}

.react-monaco-editor-container {
  direction: ltr !important;
  text-align: left;
}

.p-slider {
  direction: ltr !important;
}

.paddingRowClass {
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 5px;
  padding-top: 1px;
}

.underline-on-hover:hover {
  text-decoration: underline;
}
