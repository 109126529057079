@charset "utf-8";

div.main {
  background: linear-gradient(
    135deg,
    rgba(36, 46, 77, 0.9),
    rgba(137, 126, 121, 0.9)
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0264d6', endColorstr='#1c2b5a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  height: calc(100vh);
  width: 100%;
}

[class*="fontawesome-"]:before {
  font-family: "FontAwesome", sans-serif;
}

/* ---------- GENERAL ---------- */

* {
  box-sizing: border-box;
  /* margin: 0px auto; */

  /* &:before,
  &:after {
    box-sizing: border-box;
  } */
}

a {
  color: #eee;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  line-height: 1.5em;
}

.clearfix {
  *zoom: 1;

  /* &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  } */
}

.container {
  left: 46%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* ---------- LOGIN ---------- */

#login form {
  width: 250px;
}

#login,
.logo {
  display: inline-block;
  width: 40%;
}

.logo {
  color: rgba(255, 255, 255, 0.151);
  font-size: 50px;
  line-height: 125px;
  margin: 25px 0px 0px 20px;
}

#login form span.fa {
  background-color: #fff;
  border-radius: 3px 0px 0px 3px;
  color: #000;
  display: block;
  float: left;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.login-input {
  width: 100%;
  margin: 5px 0px 20px 0px;
}

.login-label {
  padding: 0px;
  font-weight: bold !important;
  font-size: 11px;
}

.login-button {
  width: 100%;
  margin: 5px 0px 5px 0px;
  font-weight: bold;
  text-align: center !important;
}

.eer-label {
  color: rgb(180, 33, 33);
  direction: ltr;
  font-weight: bold;
  padding-bottom: 20px;
}
