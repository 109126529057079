.charts-container {
  width: 100%;
  margin: 0rem 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-position: 100% -40%;
}

.home-charts-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  border: none;
}

.card h6 {
  text-align: center;
  margin: 0.5rem 0 0 0;
}

.card.card--bar {
  flex: 1;
  padding: 0 0.3rem;
}

.card.card--bar h6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
