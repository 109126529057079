body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
  height: 100%;
  margin: 0;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.589);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.05s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.05s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.05s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.05s 0.2s forwards;
}

.react-confirm-alert-body {
  font-size: 14px;
  width: auto;

  padding: 30px;
  text-align: center;
  background: #445566;
  background: linear-gradient(160deg, #657d94, #445566, #445566, #445566);
  border-radius: 15px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #fff;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  font-size: 23px;
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 5px !important;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  width: 120px;
  padding: 5px;
  border: 1px solid #fff;
  margin: 0 5px;
  display: inline-block;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  border-radius: 50px;
}

.react-confirm-alert-button-group > button:hover {
  background: white;
  color: #007bff;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
    -webkit-animation-duration: 0.5s;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
